<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="32"
    viewBox="0 0 20 24"
    fill="currentColor"
  >
    <g clip-path="url(#clip0_19914_317573)">
      <path
        d="M18.9144 8.1816C18.7752 8.2896 16.3176 9.6744 16.3176 12.7536C16.3176 16.3152 19.4448 17.5752 19.5384 17.6064C19.524 17.6832 19.0416 19.332 17.8896 21.012C16.8624 22.4904 15.7896 23.9664 14.1576 23.9664C12.5256 23.9664 12.1056 23.0184 10.2216 23.0184C8.3856 23.0184 7.7328 23.9976 6.24 23.9976C4.7472 23.9976 3.7056 22.6296 2.508 20.9496C1.1208 18.9768 0 15.912 0 13.0032C0 8.3376 3.0336 5.8632 6.0192 5.8632C7.6056 5.8632 8.928 6.9048 9.924 6.9048C10.872 6.9048 12.3504 5.8008 14.1552 5.8008C14.8392 5.8008 17.2968 5.8632 18.9144 8.1816ZM13.2984 3.8256C14.0448 2.94 14.5728 1.7112 14.5728 0.4824C14.5728 0.312 14.5584 0.1392 14.5272 0C13.3128 0.0456 11.868 0.8088 10.9968 1.8192C10.3128 2.5968 9.6744 3.8256 9.6744 5.0712C9.6744 5.2584 9.7056 5.4456 9.72 5.5056C9.7968 5.52 9.9216 5.5368 10.0464 5.5368C11.136 5.5368 12.5064 4.8072 13.2984 3.8256Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_19914_317573">
        <rect width="19.536" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
